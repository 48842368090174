@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */


@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-circular-progressbar/dist/styles.css";
@import "react-circular-progressbar/dist/styles.css";
@import 'react-responsive-modal/styles.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-credit-cards-2/dist/es/styles-compiled.css';

// React-Tooltip
:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}

.gradient-account-panel {
  background: radial-gradient(99.31% 94.34% at 49.88% -2.58%, rgba(232, 186, 115, 0.15) 0%, rgba(232, 186, 115, 0) 72.18%), #15171A;
}